<template>
  <div class="flexmain" style="width: 100%; height: 100%; display: flex">
    <div id="four" style="position: absolute; width: 65%; height: 80%">
      <!-- 为 坐标轴 准备一个定义了宽高的 DOM -->
      <div
        id="first"
        style="position: absolute; width: 100%; height: 90%"
      ></div>
      <div
        id="five"
        style="
          position: absolute;
          left: 15%;
          width: 60%;
          height: 8%;
          bottom: 10px;
          margin: auto;
        "
      >
        <span class="demonstration">请选择查询的时间段:</span>
        <el-date-picker
          v-model="value2"
          value-format="timestamp"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
        >
        </el-date-picker>
        <el-button icon="el-icon-search" circle @click="searchdata"></el-button>
      </div>
    </div>
    <!--    <div id="four" style="position: absolute; width: 65%; height: 10%;background: #000d4a"></div>-->
    <!-- 为 温度仪表盘 准备一个定义了宽高的 DOM -->
    <div id="panel" style="height: 100%">
      <div
        id="second"
        style="position: absolute; right: 3%; top: 6%; height: 55%; width: 20%"
      ></div>
      <!-- 为 溶氧仪表盘 准备一个定义了宽高的 DOM -->
      <div
        id="three"
        style="position: absolute; right: 3%; top: 45%; height: 50%; width: 20%"
      ></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import axios from "axios";
import moment from "moment";
import "moment/locale/zh-cn";
import Vue from "vue";
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(Element);
export default {
  inject: ["reload"],
  data: () => ({
    pickerOptions: {
      shortcuts: [
        {
          text: "最近一周",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
      ],
    },
    value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
    value2: "",
    startdata: null,
    ranges: {
      Today: [moment(), moment()],
      "This Month": [moment(), moment().endOf("month")],
    },
    mypath: null,
    // status: false,
    temp: null,
    oxygen: null,
    hun: null,
    ph: null,
    orp:null,
    realTemp: null,
    realOxygen: null,
    tempnow: null,
    Oxgennow: null,
    showtime: [],
    timeLength: null,

    myChart: null,
    secondmyChart: null,
    threemyChart: null,
  }),
  mounted() {
    let dom = document.getElementById("first");
    this.myChart = echarts.init(dom, null, {
      renderer: "canvas",
      useDirtyRect: false,
    });
    dom = document.getElementById("second");
    this.secondmyChart = echarts.init(dom);
    dom = document.getElementById("three");
    this.threemyChart = echarts.init(dom);
  },

  methods: {
    getHMS(timestamp) {
      let date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + "/";
      let M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "/";
      let D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";

      let h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      let m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      let s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return M + D + h + m + s;
    },

    searchdata: function () {
      this.searchXY(this.value2[0], this.value2[1]);
    },
    searchXY: async function (time1, time2) {
      let that = this;
      this.mypath = window.g.read_data;
      let books;
      let topic = "watertemp";
      let secondtopic = "wateroxygen";
      let threetopic = "waterhun";
      let fourtopic = "waterph";
      //获取当前时间戳
      let endtime = Date.parse(new Date());
      //获取当天0点时间戳
      let starttime =
        new Date(new Date().toLocaleDateString()).getTime() / 1000;
      // console.log(starttime);
      let this_ = this;
      //水循环温度请求
      let res = await axios({
        url: that.mypath,
        headers: {
          Authorization: localStorage.getItem("Authorization"),
        },
        method: "get",
        contentType: "application/json",
        params: {
          time1: time1,
          time2: time2,
          dataId: 9,
        },
      })
        .then((res) => {
          if (res.data.errorCode === 2001) {
            this_.$message.error("权限认证失效，请重新登录");
            this_.$router.push("/Login");
          }
          this_.temp = res.data.data;
          return this_.temp;
        }, 2000)
        .catch(function (error) {
          //alert("请求数据失败");
        });
      //水循环溶氧量请求
      let restwo = await axios({
        url: that.mypath,
        headers: {
          Authorization: localStorage.getItem("Authorization"),
        },
        method: "get",
        contentType: "application/json",
        params: {
          time1: time1,
          time2: time2,
          dataId: 10,
        },
      })
        .then((res) => {
          this_.oxygen = res.data.data;
          //console.log(res.data.data.length)
          this_.timeLength = res.data.data.length;
          //this_.showtime0 = res.data.data[0].time;
          this_.showtime=[];
          if (this_.timeLength >= 12) {
            for (let i = 0; i < 12; i++) {
              this_.showtime.push(this.getHMS(res.data.data[i].time));
            }
          }else{
            for (let i = 0; i < this_.timeLength; i++) {
              this_.showtime.push(this.getHMS(res.data.data[i].time));
            }
          }
          return this_.oxygen;
        }, 2000)
        .catch(function (error) {
          //alert("请求数据失败");
        });
      //水循环浑浊度请求
      let resthree = await axios({
        url: that.mypath,
        headers: {
          Authorization: localStorage.getItem("Authorization"),
        },
        method: "get",
        contentType: "application/json",
        params: {
          time1: time1,
          time2: time2,
          dataId: 11,
        },
      })
        .then((res) => {
          this_.hun = res.data.data;
          return this_.hun;
        }, 2000)
        .catch(function (error) {
          //alert("请求数据失败");
        });
      //水循环浑浊度请求
      let resfour = await axios({
        url: that.mypath,
        headers: {
          Authorization: localStorage.getItem("Authorization"),
        },
        method: "get",
        contentType: "application/json",
        params: {
          time1: time1,
          time2: time2,
          dataId: 12,
        },
      })
        .then((res) => {
          this_.ph = res.data.data;
          return this_.ph;
        }, 2000)
        .catch(function (error) {
          // alert("请求数据失败");
        });
      let resfive = await axios({
        url: "https://api.rslly.top/api/v2/metaData",
        headers: {
          Authorization: localStorage.getItem("Authorization"),
        },
        method: "get",
        contentType: "application/json",
        params: {
          dataId: 9,
        },
      })
        .then((res) => {
          //console.log(res.data.data[0].value)
          this_.realTemp = res.data.data[0].value;
          return this_.realTemp;
        }, 2000)
        .catch(function (error) {
          //alert("请求数据失败");
        });
      let ressix = await axios({
        url: "https://api.rslly.top/api/v2/metaData",
        headers: {
          Authorization: localStorage.getItem("Authorization"),
        },
        method: "get",
        contentType: "application/json",
        params: {
          dataId: 10,
        },
      })
        .then((res) => {
          //console.log(res.data.data[0].value)
          this_.realOxygen = res.data.data[0].value;
          return this_.realOxygen;
        }, 2000)
        .catch(function (error) {
          //alert("请求数据失败");
        });
        let resseven = await axios({
        url: that.mypath,
        headers: {
          Authorization: localStorage.getItem("Authorization"),
        },
        method: "get",
        contentType: "application/json",
        params: {
          time1: time1,
          time2: time2,
          dataId: 14,
        },
      })
        .then((res) => {
          this_.orp = res.data.data;
          return this_.orp;
        }, 2000)
        .catch(function (error) {
          // alert("请求数据失败");
        });
        

      this.oxygen = restwo;
      this.temp = res;
      this.hun = resthree;
      this.ph = resfour;
      this.orp= resseven;
      this.realTemp = resfive;
      this.realOxygen = ressix;
      this.first();
      this.second();
    },

    first: function () {
      const colors = [
        "#47bbad",
        "rgba(187,154,52,0.6)",
        "#fd7347",
        "rgba(46,83,196,0.78)",
      ];
      let firstoption;
      //     username:name
      // }
      // console.log(resp.data.data)

      let myChart = this.myChart;
      firstoption = {
        
        dataZoom: [
          {
            show: true,
            start: 0,
            end: 100,
          },
          {
            type: "inside",
            start: 0,
            end: 100,
          },
        ],
        color: colors,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
          formatter:
            "{a0}: {c0}<br />{a1}: {c1}JTU<br />{a2}: {c2}°C<br />{a3}: {c3}mg/L<br />{a4}: {c4}mv",
        },
        grid: {
          right: "16%",
          left: "16%",
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        legend: {
          data: ["PH值", "浑浊度", "温度", "溶氧","氧化还原电位"],
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              alignWithLabel: true,
            },
            // prettier-ignore
            //data: [this.getHMS(this.showtime0),this.getHMS(this.showtime1),this.getHMS(this.showtime2),this.getHMS(this.showtime3),this.getHMS(this.showtime4),this.getHMS(this.showtime5)],
            data: this.showtime
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "PH值",
            position: "right",
            min: "dataMin", //取最小值为最小刻度
            max: "dataMax", //取最大值为最大刻度

            min: function (value) {
              //取最小值向下取整为最小刻度
              return Math.floor(value.min);
            },
            max: function (value) {
              //取最大值向上取整为最大刻度
              return Math.ceil(value.max);
            },

            minInterval: 0.1, //分割刻度
            scale: true, //自适应
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[0],
              },
            },
            axisLabel: {
              formatter: "{value} ",
            },
          },
          {
            type: "value",
            name: "浑浊度",
            position: "right",
            min: "dataMin", //取最小值为最小刻度
            max: "dataMax", //取最大值为最大刻度

            min: function (value) {
              //取最小值向下取整为最小刻度
              return Math.floor(value.min);
            },
            max: function (value) {
              //取最大值向上取整为最大刻度
              return Math.ceil(value.max);
            },

            scale: true, //自适应
            alignTicks: true,
            offset: 60,
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[1],
              },
            },
            axisLabel: {
              formatter: "{value} JTU",
            },
          },
          {
            type: "value",
            name: "温度",
            position: "left",
            min: "dataMin", //取最小值为最小刻度
            max: "dataMax", //取最大值为最大刻度

            min: function (value) {
              // console.log(Math.floor(value.min))
              //取最小值向下取整为最小刻度
              return Math.floor(value.min);
            },
            max: function (value) {
              // console.log(Math.ceil(value.max))
              //取最大值向上取整为最大刻度
              return Math.ceil(value.max);
            },

            scale: true, //自适应
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[2],
              },
            },
            axisLabel: {
              formatter: "{value} °C",
            },
          },
          {
            type: "value",
            name: "溶氧",
            position: "left",
            alignTicks: true,

            min: "dataMin", //取最小值为最小刻度
            max: "dataMax", //取最大值为最大刻度

            min: function (value) {
              //取最小值向下取整为最小刻度
              return Math.floor(value.min);
            },
            max: function (value) {
              //取最大值向上取整为最大刻度
              return Math.ceil(value.max);
            },

            scale: true, //自适应

            offset: 60,
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[3],
              },
            },
            axisLabel: {
              formatter: "{value} mg/L",
            },
            
          },
          {
            type: "value",
            name: "氧化还原电位",
            position: "right",
            alignTicks: true,

            min: "dataMin", //取最小值为最小刻度
            max: "dataMax", //取最大值为最大刻度

            min: function (value) {
              //取最小值向下取整为最小刻度
              return Math.floor(value.min);
            },
            max: function (value) {
              //取最大值向上取整为最大刻度
              return Math.ceil(value.max);
            },

            scale: true, //自适应

            offset: 60,
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[3],
              },
            },
            axisLabel: {
              formatter: "{value} mv",
            },
            
          }
        ],
        series: [
          {
            name: "PH值",
            barWidth: 40,
            type: "bar",
            textAlign: "center",
            data: this.ph,
          },
          {
            barGap: "-75%" /*这里设置包含关系，只需要这一句话*/,
            barWidth: 20,
            name: "浑浊度",
            type: "bar",
            textAlign: "center",
            yAxisIndex: 1,
            data: this.hun,
          },
          {
            name: "温度",
            type: "line",
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 3,
                },
              },
            },
            smooth: true,
            yAxisIndex: 2,
            data: this.temp,
          },
          {
            name: "溶氧",
            type: "line",
            smooth: true,
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 3,
                  type: "dotted", //'dotted'虚线 'solid'实线
                },
              },
            },
            yAxisIndex: 3,
            data: this.oxygen,
          },
           {
            name: "氧化还原电位",
            type: "line",
            smooth: true,
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 3,
                  type: "dotted", //'dotted'虚线 'solid'实线
                },
              },
            },
            yAxisIndex: 4,
            data: this.orp,
          },
        ],
      };
      if (firstoption && typeof firstoption === "object") {
        myChart.setOption(firstoption);
      }
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    second: function () {
      const colors = [
        "#47bbad",
        "rgba(187,154,52,0.6)",
        "#fd7347",
        "rgba(46,83,196,0.78)",
      ];
      // var firstoption;
      // var dom = document.getElementById('first');
      // var myChart = echarts.init(dom, null, {
      //     renderer: 'canvas',
      //     useDirtyRect: false
      // });
      //     username:name
      // }
      // console.log(resp.data.data)
      // var dom = document.getElementById('first');
      // var myChart = echarts.init(dom, null, {
      //     renderer: 'canvas',
      //     useDirtyRect: false
      // });
      //温度实时表盘
      let secondoption;
      let secondmyChart = this.secondmyChart;
      let this_ = this;
      this.mypath = window.g.read_data;
      var books;
      var topic = "watertemp";
      var secondtopic = "wateroxygen";
      var threetopic = "waterhun";
      var fourtopic = "waterph";
      //获取当前时间戳
      var endtime = Date.parse(new Date());
      //获取当天0点时间戳
      let starttime =
        new Date(new Date().toLocaleDateString()).getTime() / 1000;

      //温度实时值温度请求
      axios({
        url: "https://api.rslly.top/api/v2/metaData",
        headers: {
          Authorization: localStorage.getItem("Authorization"),
        },
        method: "get",
        contentType: "application/json",
        params: {
          dataId: 9,
        },
      })
        .then((res) => {
          this_.tempnow = res.data.data[0].value;
          // alert(this_.getHMS(this_.showtime))
          return this_.tempnow;
        }, 2000)
        .catch(function (error) {
          // alert("请求数据失败");
        });
      secondoption = {
        // grid: {
        //     right: '0%',
        //     left:'16%'
        // },
        series: [
          {
            type: "gauge",
            center: ["50%", "60%"],
            startAngle: 200,
            endAngle: -20,
            min: 0,
            max: 60,
            splitNumber: 12,
            itemStyle: {
              color: "#FFAB91",
            },
            progress: {
              show: true,
              width: 60,
            },
            pointer: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 20,
              },
            },
            axisTick: {
              distance: -35,
              splitNumber: 5,
              lineStyle: {
                width: 2,
                color: "#999",
              },
            },
            splitLine: {
              distance: -42,
              length: 14,
              lineStyle: {
                width: 2,
                color: "#999",
              },
            },
            axisLabel: {
              distance: -11,
              color: "#999",
              fontSize: 15,
            },
            anchor: {
              show: false,
            },
            title: {
              show: false,
            },
            detail: {
              valueAnimation: true,
              width: "80%",
              lineHeight: 20,
              borderRadius: 8,
              offsetCenter: [0, "-15%"],
              fontSize: 15,
              fontWeight: "bolder",
              formatter: "{value} °C",
              color: "auto",
            },
            data: [
              {
                value: this.tempnow,
              },
            ],
          },
          {
            type: "gauge",
            center: ["50%", "60%"],
            startAngle: 200,
            endAngle: -20,
            min: 0,
            max: 60,
            itemStyle: {
              color: "#fd7347",
            },
            progress: {
              show: true,
              width: 8,
            },
            pointer: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: this.tempnow,
              },
            ],
          },
        ],
      };
      secondoption && secondmyChart.setOption(secondoption);
      let threeoption;
      let threemyChart = this.threemyChart;
      //温度实时值温度请求
      axios({
        url: "https://api.rslly.top/api/v2/metaData",
        headers: {
          Authorization: localStorage.getItem("Authorization"),
        },
        method: "get",
        contentType: "application/json",
        params: {
          dataId: 10,
        },
      })
        .then((res) => {
          this_.Oxgennow = res.data.data[0].value;
          return this_.Oxgennow;
        }, 2000)
        .catch(function (error) {
          // alert("请求数据失败");
        });
      threeoption = {
        tooltip: {
          formatter: "{a} <br/>{b} : {c}%",
        },
        series: [
          {
            name: "Pressure",
            type: "gauge",
            progress: {
              show: true,
            },
            detail: {
              valueAnimation: true,
              textStyle: {
                fontSize: 19,
              },
              formatter: "{value}mg/L",
            },
            title: {
              //设置仪表盘中间显示文字样式
              textStyle: {
                fontWeight: "bold",
                fontSize: 1,
              },
            },
            data: [
              {
                value: this.Oxgennow,
                name: "溶氧量",
              },
            ],
          },
        ],
      };
      threeoption && threemyChart.setOption(threeoption);
    },
  },
  async created() {
    let time2 = Date.parse(new Date());
    this.searchXY(new Date(new Date().toLocaleDateString()).getTime(), time2);
    // 实现轮询
    window.setInterval(() => {
      setTimeout(this.second(), 0);
    }, 1000);
  },
  destroyed() {
    window.clearInterval(window.setInterval);
  },
};
</script>

<style scoped>
</style>